import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import '../styles/Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const navigate = useNavigate();

  const handleGetStarted = () => {
    navigate('/');
    setTimeout(() => {
      const guideSection = document.getElementById('free-guide-section');
      if (guideSection) {
        guideSection.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">
          <img 
            src={process.env.PUBLIC_URL + "/Images/logo.png"} 
            alt="Executive Press Logo" 
            className="navbar-logo-img"
          />
          <span className="navbar-logo-text">Executive Press</span>
        </Link>
        
        <div className={`navbar-menu ${isOpen ? 'active' : ''}`}>
          <div className="phone-number">
            <FontAwesomeIcon icon={faPhone} className="gradient-icon" />
            <span className="number gradient-text">+1 213 457 3593</span>
          </div>
          <div 
            className="nav-item-dropdown"
            onMouseEnter={() => setIsServicesOpen(true)}
            onMouseLeave={() => setIsServicesOpen(false)}
          >
            <span className="nav-item">
              Services
              <span className="dropdown-arrow">▼</span>
            </span>
            <div className={`dropdown-menu ${isServicesOpen ? 'show' : ''}`}>
              <Link to="/publishing-services" className="dropdown-item">Publishing Services</Link>
              <Link to="/marketing-services" className="dropdown-item">Marketing Services</Link>
            </div>
          </div>
          <Link to="/about" className="nav-item">About</Link>
          <Link to="/contact" className="nav-item">Contact</Link>
          <button className="nav-button" onClick={handleGetStarted}>Get Started</button>
        </div>

        <div className="hamburger" onClick={() => setIsOpen(!isOpen)}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 