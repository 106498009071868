import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { setHours, setMinutes } from 'date-fns';
import '../styles/MarketingServices.css';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const MarketingServices = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [strategyFormData, setStrategyFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });
  const [successType, setSuccessType] = useState('');
  const [loadingType, setLoadingType] = useState('');

  const marketingServices = [
    {
      title: "Book Launch Campaign",
      description: "A comprehensive marketing campaign to launch your book successfully",
      bullets: [
        "Press Release Writing & Distribution",
        "Social Media Campaign",
        "Email Marketing Campaign",
        "Blog Tour Coordination",
        "Online Advertising Strategy",
        "Launch Event Planning",
        "Media Kit Creation"
      ]
    },
    {
      title: "Author Platform Building",
      description: "Establish and grow your presence as an author",
      bullets: [
        "Author Website Development",
        "Social Media Profile Setup",
        "Content Strategy Development",
        "Email List Building",
        "Brand Identity Creation",
        "Online Community Building",
        "Author Blog Setup & Strategy"
      ]
    },
    {
      title: "Media Exposure",
      description: "Get your book noticed by the right audience",
      bullets: [
        "Media Contact List Building",
        "Press Release Distribution",
        "Radio Interview Booking",
        "Podcast Appearance Coordination",
        "TV Segment Pitching",
        "Online Media Outreach",
        "Journalist Connection Building"
      ]
    },
    {
      title: "Digital Marketing",
      description: "Leverage online channels to reach more readers",
      bullets: [
        "Amazon Marketing Services",
        "Social Media Advertising",
        "Google Ads Management",
        "Email Marketing Campaigns",
        "SEO Optimization",
        "Content Marketing",
        "Analytics & Performance Tracking"
      ]
    },
    {
      title: "Acquisition for Traditional Publishing",
      description: "Strategic guidance and representation for authors seeking traditional publishing deals",
      bullets: [
        "Query Letter Development",
        "Book Proposal Writing",
        "Agent Research & Matching",
        "Publisher Submission Strategy",
        "Contract Review & Negotiation",
        "Industry Networking Support",
        "Publishing House Connections"
      ]
    },
    {
      title: "Film & TV Adaptation Services",
      description: "Transform your book into a compelling visual narrative for screen adaptation",
      bullets: [
        "Screenplay Adaptation",
        "Film Rights Marketing",
        "Producer & Studio Outreach",
        "Industry Contact Building",
        "Pitch Deck Creation",
        "Film Market Representation",
        "Adaptation Rights Negotiation"
      ]
    }
  ];

  // Filter available times (9 AM to 5 PM EST, weekdays only)
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    // Convert to EST
    const estOffset = -4; // EDT offset from UTC
    const localOffset = currentDate.getTimezoneOffset() / 60;
    const offsetDiff = localOffset + estOffset;
    
    currentDate.setHours(currentDate.getHours() + offsetDiff);

    return currentDate.getTime() < selectedDate.getTime();
  };

  const isWeekday = (date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);
      setLoadingType('consultation');
      
      // Format the date for email
      const formattedDate = selectedDate ? 
        new Date(selectedDate).toLocaleString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          timeZone: 'America/New_York'
        }) + ' EST' : 'No date selected';

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        message: `
New Consultation Request:
------------------------
Name: ${formData.name}
Email: ${formData.email}
Phone: ${formData.phone}
Requested Time: ${formattedDate}
        `,
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setShowModal(false);
        setSuccessType('consultation');
        setShowSuccessDialog(true);
        // Reset form
        setFormData({
          name: '',
          phone: '',
          email: ''
        });
        setSelectedDate(null);
      }
    } catch (error) {
      setIsLoading(false);
      setLoadingType('');
      console.error('Error:', error);
      alert('There was an error scheduling your consultation. Please try again or contact us directly.');
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleStrategyInputChange = (e) => {
    setStrategyFormData({
      ...strategyFormData,
      [e.target.name]: e.target.value
    });
  };

  const handleStrategySubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);
      setLoadingType('strategy');

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: `${strategyFormData.firstName} ${strategyFormData.lastName}`,
        from_email: strategyFormData.email,
        from_phone: strategyFormData.phone,
        message: `
New Marketing Strategy Request:
-----------------------------
Name: ${strategyFormData.firstName} ${strategyFormData.lastName}
Email: ${strategyFormData.email}
Phone: ${strategyFormData.phone}
Marketing Goals: ${strategyFormData.message}
        `,
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setSuccessType('strategy');
        setShowSuccessDialog(true);
        // Reset form
        setStrategyFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      setIsLoading(false);
      setLoadingType('');
      console.error('Error:', error);
      alert('There was an error sending your request. Please try again or contact us directly.');
    }
  };

  return (
    <div className="marketing-services">
      <section className="hero-section">
        <div className="hero-content">
          <h1>Marketing Services</h1>
          <p style={{ color: 'white' }}>Strategic book marketing solutions to help your book reach its target audience and achieve maximum visibility in the marketplace.</p>
        </div>
      </section>

      <section className="services-grid">
        {marketingServices.map((service, index) => (
          <div key={index} className="service-card">
            <h2>{service.title}</h2>
            <p className="service-description">{service.description}</p>
            <ul className="service-bullets">
              {service.bullets.map((bullet, bulletIndex) => (
                <li key={bulletIndex}>{bullet}</li>
              ))}
            </ul>
          </div>
        ))}
      </section>

      <section className="cta-section">
        <div className="container">
          <h2>Ready to Market Your Book?</h2>
          <p>Let's create a marketing strategy that works for you</p>
          <button className="cta-button-outline" onClick={() => setShowModal(true)}>
            Schedule a Consultation
          </button>
        </div>
      </section>

      {/* Consultation Modal */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowModal(false)}>×</button>
            <h2>Schedule Your Consultation</h2>
            <p>Select your preferred date and time (EST)</p>
            <form onSubmit={handleSubmit} className="consultation-form">
              <div className="form-group">
                <input
                  type="text"
                  placeholder="Your Name"
                  value={formData.name}
                  onChange={(e) => setFormData({...formData, name: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="tel"
                  placeholder="Phone Number"
                  value={formData.phone}
                  onChange={(e) => setFormData({...formData, phone: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Email Address"
                  value={formData.email}
                  onChange={(e) => setFormData({...formData, email: e.target.value})}
                  required
                />
              </div>
              <div className="form-group">
                <DatePicker
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  showTimeSelect
                  filterDate={isWeekday}
                  filterTime={filterPassedTime}
                  minTime={setHours(setMinutes(new Date(), 0), 9)}
                  maxTime={setHours(setMinutes(new Date(), 0), 17)}
                  dateFormat="MMMM d, yyyy h:mm aa"
                  placeholderText="Select Date and Time (EST)"
                  className="date-picker"
                  required
                />
              </div>
              <button type="submit" className="submit-button">Schedule Consultation</button>
            </form>
          </div>
        </div>
      )}

      <section className="contact-form">
        <h2>Get Your Marketing Strategy</h2>
        <form className="quote-form" onSubmit={handleStrategySubmit}>
          <div className="form-row">
            <div className="form-group">
              <input 
                type="text" 
                name="firstName"
                placeholder="First Name" 
                value={strategyFormData.firstName}
                onChange={handleStrategyInputChange}
                required 
              />
            </div>
            <div className="form-group">
              <input 
                type="text" 
                name="lastName"
                placeholder="Last Name" 
                value={strategyFormData.lastName}
                onChange={handleStrategyInputChange}
                required 
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <input 
                type="email" 
                name="email"
                placeholder="Email" 
                value={strategyFormData.email}
                onChange={handleStrategyInputChange}
                required 
              />
            </div>
            <div className="form-group">
              <input 
                type="tel" 
                name="phone"
                placeholder="Phone" 
                value={strategyFormData.phone}
                onChange={handleStrategyInputChange}
                required 
              />
            </div>
          </div>
          <div className="form-group full-width">
            <textarea 
              name="message"
              placeholder="Tell us about your book and marketing goals" 
              rows="5" 
              value={strategyFormData.message}
              onChange={handleStrategyInputChange}
              required
            ></textarea>
          </div>
          <button type="submit" className="submit-button">Get Started</button>
        </form>
      </section>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p className="loading-text">
              {loadingType === 'consultation' 
                ? "Scheduling your consultation..." 
                : "Sending your request..."}
            </p>
          </div>
        </div>
      )}

      {/* Success Dialog */}
      <div className={`dialog-overlay ${showSuccessDialog ? 'show' : ''}`}>
        <div className={`success-dialog ${showSuccessDialog ? 'show' : ''}`}>
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <h3>Thank You!</h3>
          {successType === 'consultation' ? (
            <p>Your consultation has been scheduled successfully. We will contact you shortly to confirm.</p>
          ) : (
            <p>Your request has been sent. We will contact you shortly to confirm.</p>
          )}
          <button onClick={handleCloseSuccessDialog} className="close-dialog-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default MarketingServices; 