import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhone, faEnvelope, faCheck } from '@fortawesome/free-solid-svg-icons';
import emailjs from '@emailjs/browser';
import '../styles/Contact.css';

const Contact = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: `${formData.firstName} ${formData.lastName}`,
        from_email: formData.email,
        from_phone: formData.phone,
        message: `
New Contact Form Submission:
------------------
Full Name: ${formData.firstName} ${formData.lastName}
Email: ${formData.email}
Phone Number: ${formData.phone}
Message: ${formData.message}
        `,
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setShowSuccessDialog(true);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          message: ''
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      alert('There was an error sending your message. Please try again or contact us directly.');
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  return (
    <div className="contact-page">
      <section className="contact-hero">
        <h1>CONTACT US</h1>
        <div className="hero-underline"></div>
      </section>

      <section className="contact-info-section">
        <div className="container">
          <div className="info-cards">
            <div className="info-card">
              <FontAwesomeIcon icon={faMapMarkerAlt} className="info-icon" />
              <h3>Visit Us</h3>
              <p>960 - 10611 98 Avenue<br />Edmonton, AB T5K2P7</p>
            </div>
            <div className="info-card">
              <FontAwesomeIcon icon={faPhone} className="info-icon" />
              <h3>Call Us</h3>
              <p>+1 213 457 3593</p>
            </div>
            <div className="info-card">
              <FontAwesomeIcon icon={faEnvelope} className="info-icon" />
              <h3>Email Us</h3>
              <p>info@executive-press.com</p>
            </div>
          </div>
        </div>
      </section>

      <section className="map-section">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2370.9089745427567!2d-113.49070572320168!3d53.54726997246425!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53a022459c4ac825%3A0x7f75d6b7c8d9c278!2s10611%2098%20Ave%20NW%2C%20Edmonton%2C%20AB%20T5K%202P7!5e0!3m2!1sen!2sca!4v1689882899367!5m2!1sen!2sca"
          width="100%"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>

      <section className="contact-form-section">
        <div className="container">
          <div className="form-content">
            <div className="form-header">
              <h2>Send Us a Message</h2>
              <p>Have questions about our publishing services? We'd love to hear from you.</p>
            </div>
            <form className="contact-form" onSubmit={handleSubmit}>
              <div className="form-row">
                <input 
                  type="text" 
                  name="firstName"
                  placeholder="First Name" 
                  value={formData.firstName}
                  onChange={handleInputChange}
                  required 
                />
                <input 
                  type="text" 
                  name="lastName"
                  placeholder="Last Name" 
                  value={formData.lastName}
                  onChange={handleInputChange}
                  required 
                />
              </div>
              <div className="form-row">
                <input 
                  type="email" 
                  name="email"
                  placeholder="Email" 
                  value={formData.email}
                  onChange={handleInputChange}
                  required 
                />
                <input 
                  type="tel" 
                  name="phone"
                  placeholder="Phone" 
                  value={formData.phone}
                  onChange={handleInputChange}
                  required 
                />
              </div>
              <textarea 
                name="message"
                placeholder="Your Message" 
                rows="6" 
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
              <button type="submit" className="submit-button">Send Message</button>
            </form>
          </div>
        </div>
      </section>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p className="loading-text">Sending your message...</p>
          </div>
        </div>
      )}

      {/* Success Dialog */}
      <div className={`dialog-overlay ${showSuccessDialog ? 'show' : ''}`}>
        <div className={`success-dialog ${showSuccessDialog ? 'show' : ''}`}>
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <h3>Thank You!</h3>
          <p>Your message has been sent successfully. We will get back to you shortly.</p>
          <button onClick={handleCloseSuccessDialog} className="close-dialog-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default Contact; 