import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCreditCard } from '@fortawesome/free-solid-svg-icons';
import '../styles/PublishingServices.css';

const PublishingServices = () => {
  const [showModal, setShowModal] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState('');
  const [manuscript, setManuscript] = useState(null);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: ''
  });
  const [quoteFormData, setQuoteFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: ''
  });
  const [successType, setSuccessType] = useState('');

  const handlePackageSelect = (packageName) => {
    setSelectedPackage(packageName);
    setShowModal(true);
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);

      if (manuscript && manuscript.size > 5 * 1024 * 1024) {
        throw new Error('File size exceeds 5MB limit');
      }

      let fileData = '';
      let fileName = '';
      
      if (manuscript) {
        fileData = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => resolve(e.target.result);
          reader.onerror = (e) => reject(e);
          reader.readAsDataURL(manuscript);
        });
        fileName = manuscript.name;
      }

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: formData.fullName,
        from_email: formData.email,
        from_phone: formData.phone,
        package_type: selectedPackage,
        message: `New Package Selection:
Package Type: ${selectedPackage}
Name: ${formData.fullName}
Email: ${formData.email}
Phone: ${formData.phone}
Manuscript: ${fileName || 'No file uploaded'}`,
        file: fileData,
        filename: fileName
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setSuccessType('package');
        setShowModal(false);
        setShowSuccessDialog(true);
        setManuscript(null);
        setFormData({
          fullName: '',
          email: '',
          phone: ''
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      
      let errorMessage = 'There was an error submitting your form. ';
      if (error.message === 'File size exceeds 5MB limit') {
        errorMessage = 'File size must be under 5MB. ';
      }
      errorMessage += 'Please try again or contact us directly at liamhenry@execupress.com';
      
      alert(errorMessage);
    }
  };

  const handleCloseSuccessDialog = () => {
    setShowSuccessDialog(false);
  };

  const handleQuoteSubmit = async (e) => {
    e.preventDefault();
    
    try {
      setIsLoading(true);

      const templateParams = {
        to_name: 'Liam Henry',
        from_name: `${quoteFormData.firstName} ${quoteFormData.lastName}`,
        from_email: quoteFormData.email,
        from_phone: quoteFormData.phone,
        package_type: 'Quote Request',
        message: `New Quote Request:
Name: ${quoteFormData.firstName} ${quoteFormData.lastName}
Email: ${quoteFormData.email}
Phone: ${quoteFormData.phone}`,
        file: '',
        filename: ''
      };

      emailjs.init("KqRJLAWe3g_UkcB2p");

      const response = await emailjs.send(
        'service_853ldth',
        'template_fsco3b9',
        templateParams
      );

      if (response.status === 200) {
        setIsLoading(false);
        setSuccessType('quote');
        setShowSuccessDialog(true);
        setQuoteFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: ''
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error('Error:', error);
      
      let errorMessage = 'There was an error submitting your form. ';
      errorMessage += 'Please try again or contact us directly at liamhenry@execupress.com';
      
      alert(errorMessage);
    }
  };

  const handleQuoteInputChange = (e) => {
    setQuoteFormData({
      ...quoteFormData,
      [e.target.name]: e.target.value
    });
  };

  return (
    <div className="publishing-services">
      <section className="process-section">
        <h2>Our Publishing Process</h2>
        <div className="process-steps">
          <div className="step">
            <div className="step-number">1</div>
            <h3>Manuscript Submission</h3>
            <p>Submit your completed manuscript and choose your publishing package</p>
          </div>
          <div className="step">
            <div className="step-number">2</div>
            <h3>Editorial Review</h3>
            <p>Professional evaluation and feedback on your manuscript</p>
          </div>
          <div className="step">
            <div className="step-number">3</div>
            <h3>Production</h3>
            <p>Cover design, interior formatting, and proof review</p>
          </div>
          <div className="step">
            <div className="step-number">4</div>
            <h3>Publication</h3>
            <p>Your book goes live across all major platforms</p>
          </div>
        </div>
      </section>

      <section className="packages-section">
        <h2>Publishing Packages</h2>
        <div className="packages-grid">
          <div className="package-card">
            <h3>Select</h3>
            <p className="package-description">Perfect for authors seeking essential publishing services</p>
            <div className="price">$899</div>
            <ul>
              <li>Custom Cover Design</li>
              <li>Interior Layout & Formatting</li>
              <li>ISBN Assignment</li>
              <li>Paperback & eBook Formats</li>
              <li>Online Distribution</li>
              <li>Author Support Team</li>
              <li><strong>3 Paperback Copies</strong></li>
            </ul>
            <button className="select-package" onClick={() => handlePackageSelect('Select')}>
              Select Package
            </button>
          </div>

          <div className="package-card">
            <h3>Premium</h3>
            <p className="package-description">Comprehensive publishing package with marketing essentials</p>
            <div className="price">$1,899</div>
            <ul>
              <li>Everything in Select, plus:</li>
              <li>Professional Editorial Review</li>
              <li>Marketing Strategy Session</li>
              <li>Press Release Creation</li>
              <li>Social Media Starter Kit</li>
              <li>Author Website Setup</li>
              <li><strong>5 Paperback Copies</strong></li>
            </ul>
            <button className="select-package" onClick={() => handlePackageSelect('Premium')}>
              Select Package
            </button>
          </div>

          <div className="package-card">
            <h3>Elite</h3>
            <p className="package-description">Full-service publishing with maximum market exposure</p>
            <div className="price">$2,899</div>
            <ul>
              <li>Everything in Premium, plus:</li>
              <li>Developmental Editing</li>
              <li>Customized Marketing Plan</li>
              <li>Book Launch Campaign</li>
              <li>Video Book Trailer</li>
              <li>Book Signing Event Support</li>
              <li><strong>5 Paperback & 3 Hardcover Copies</strong></li>
            </ul>
            <button className="select-package" onClick={() => handlePackageSelect('Elite')}>
              Select Package
            </button>
          </div>
        </div>
      </section>

      {/* Package Selection Modal */}
      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <div className="modal-content" onClick={e => e.stopPropagation()}>
            <button className="close-button" onClick={() => setShowModal(false)}>×</button>
            <h2>Complete Your Package Selection</h2>
            <h3>{selectedPackage} Package</h3>
            
            <div className="payment-notice">
              <div className="payment-notice-header">
                <div className="payment-icon">
                  <FontAwesomeIcon icon={faCreditCard} />
                </div>
                <h4>Payment Terms</h4>
              </div>
              <div className="payment-terms">
                <div className="payment-term-item">
                  <FontAwesomeIcon icon={faCheck} />
                  <p>50% initial payment to launch your project</p>
                </div>
                <div className="payment-term-item">
                  <FontAwesomeIcon icon={faCheck} />
                  <p>Remaining 50% upon successful project completion</p>
                </div>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="package-form">
              <div className="form-group">
                <input 
                  type="text" 
                  name="fullName"
                  placeholder="Full Name" 
                  required 
                  value={formData.fullName}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <input 
                  type="email" 
                  name="email"
                  placeholder="Email Address" 
                  required 
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group">
                <input 
                  type="tel" 
                  name="phone"
                  placeholder="Phone Number" 
                  required 
                  value={formData.phone}
                  onChange={handleInputChange}
                />
              </div>
              <div className="form-group file-upload">
                <span className="file-upload-label">Upload Manuscript (Optional)</span>
                <input
                  type="file"
                  id="manuscript"
                  accept=".doc,.docx,.pdf"
                  onChange={(e) => setManuscript(e.target.files[0])}
                  disabled
                />
                <label htmlFor="manuscript" style={{ opacity: 0.6, cursor: 'not-allowed' }}>Choose a File</label>
                <p className="file-hint">File upload feature is temporarily disabled. Please contact us directly to submit your manuscript.</p>
              </div>
              <button type="submit" className="submit-button">Submit Package Selection</button>
            </form>
          </div>
        </div>
      )}

      <section className="contact-form" id="quote-section">
        <h2>Get a Quote</h2>
        <form className="quote-form" onSubmit={handleQuoteSubmit}>
          <div className="form-row">
            <input 
              type="text" 
              name="firstName"
              placeholder="First Name" 
              required 
              value={quoteFormData.firstName}
              onChange={handleQuoteInputChange}
            />
            <input 
              type="text" 
              name="lastName"
              placeholder="Last Name" 
              required 
              value={quoteFormData.lastName}
              onChange={handleQuoteInputChange}
            />
          </div>
          <div className="form-row">
            <input 
              type="email" 
              name="email"
              placeholder="Email" 
              required 
              value={quoteFormData.email}
              onChange={handleQuoteInputChange}
            />
            <input 
              type="tel" 
              name="phone"
              placeholder="Phone" 
              required 
              value={quoteFormData.phone}
              onChange={handleQuoteInputChange}
            />
          </div>
          <button type="submit" className="submit-button">Send</button>
        </form>
      </section>

      {/* Loading Spinner */}
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-spinner">
            <div className="spinner"></div>
            <p className="loading-text">Sending your request...</p>
          </div>
        </div>
      )}

      {/* Success Dialog */}
      <div className={`dialog-overlay ${showSuccessDialog ? 'show' : ''}`}>
        <div className={`success-dialog ${showSuccessDialog ? 'show' : ''}`}>
          <div className="checkmark">
            <FontAwesomeIcon icon={faCheck} />
          </div>
          <h3>Thank You!</h3>
          {successType === 'quote' ? (
            <p>Your free quote request has been sent successfully. We will contact you shortly.</p>
          ) : (
            <p>Your package selection has been submitted successfully. We will contact you shortly.</p>
          )}
          <button onClick={handleCloseSuccessDialog} className="close-dialog-button">Close</button>
        </div>
      </div>
    </div>
  );
};

export default PublishingServices; 